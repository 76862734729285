import { api } from './api';

export const getNewsPaginated = (page: number, pageSize: number, searchText: string) => {
  return api.get(`/v1/news/paginated?page=${page}&pageSize=${pageSize}&searchText=${searchText}`);
}

export const getNewsById = (newsCategoryId: string) => {
  return api.get(`/v1/news/${newsCategoryId}`);
}

export const createNews = (caption: string, categories: string[], thumb: string, banner: string, content: string, slug: string) => {
  return api.post(`/v1/news`, { caption, categories, thumb, banner, content, slug });
}

export const updateNewsById = (id: string, caption: string, categories: string[], thumb: string, banner: string, content: string, slug: string) => {
  return api.put(`/v1/news/${id}`, { caption, categories, thumb, banner, content, slug });
}

export const deleteNews = (newsId: string) => {
  return api.delete(`/v1/news/${newsId}`);
}
